export const hwGreen = {
	50: '#e5f5ed',
	100: '#c0e6d2',
	200: '#98d6b5',
	300: '#6cc799',
	400: '#47ba84',
	500: '#14ae6f',
	600: '#0b9f64',
	700: '#008d57', // main
	800: '#007c4b',
	900: '#005c36'
};

export const hwOrange = {
	50: '#fef3e0',
	100: '#fddfb2',
	200: '#fccb80',
	300: '#fab64d',
	400: '#faa627',
	500: '#f99702',
	600: '#f58b01',
	700: '#ef7b00', // main
	800: '#e96c00',
	900: '#e05200'
};

export const hqRed = {
	50: '#ffeaed',
	100: '#ffcbce',
	200: '#fb9692',
	300: '#f46b66',
	400: '#fe443d',
	500: '#ff2d19',
	600: '#f41e1c',
	700: '#e20817', // main
	800: '#d5000d',
	900: '#c80000'
};

export const tibBlue = {
	50: '#e3f2fd',
	100: '#bbdefb',
	200: '#90caf9',
	300: '#64b5f6',
	400: '#42a5f5',
	500: '#2196f3',
	600: '#1e88e5',
	700: '#1976d2', // main
	800: '#1565c0',
	900: '#0d47a1'
};

export const hwLightGreen = {
	50: '#f1f7e9',
	100: '#ddebc8',
	200: '#c6dda5',
	300: '#b0d081', // main
	400: '#9fc666',
	500: '#8fbd4a',
	600: '#80ad42',
	700: '#6c9938',
	800: '#59852f',
	900: '#38641e'
};

export { grey } from '@mui/material/colors';

export const blueGrey = {
	50: '#eeeef1',
	100: '#d3d6de',
	200: '#b8bbc7',
	300: '#9ba0b0',
	400: '#868b9e',
	500: '#71778e',
	600: '#63697d',
	700: '#515666', // main
	800: '#404450',
	900: '#2d2f39'
};

export const hfBrown = {
	50: '#ffe8ba',
	100: '#faca9e',
	200: '#e0ac7e',
	300: '#c38e5b',
	400: '#ac763f',
	500: '#965f24', // main
	600: '#8a541e',
	700: '#7a4715',
	800: '#6d390f',
	900: '#5e2a05'
};

export const hfGreen = {
	50: '#e7f6ec',
	100: '#c4ead1',
	200: '#9fdcb4',
	300: '#75cf97',
	400: '#54c480',
	500: '#2eb96a',
	600: '#26aa5f',
	700: '#1c9753',
	800: '#138647',
	900: '#006633' // main
};

export const bgLevelsDark = {
	level1: '#141524',
	level2: '#20212f',
	level3: '#252633',
	level4: '#262735',
	level5: '#292a38',
	level6: '#2e2f3c',
	level7: '#31313f',
	level8: '#353643',
	level9: '#373845',
	level10: '#3a3b47'
};

export const facebookColor = '#1877f2';
export const instagramColor = '#c32aa3';
export const pinterestColor = '#bd081c';
export const whatsAppColor = '#25d366';
export const youtubeColor = '#FF0000';
