import { alpha as fade, darken, lighten, rgbToHex } from '@mui/material/styles';
import { extendTheme, backgroundLevels } from 'react-app/styles/theme';
import deepmerge from 'deepmerge';
import {
	hwGreen,
	hwOrange,
	hqRed,
	tibBlue,
	hfBrown,
	hfGreen,
	grey,
	blueGrey,
	hwLightGreen,
	bgLevelsDark,
	facebookColor,
	instagramColor,
	pinterestColor,
	whatsAppColor,
	youtubeColor
} from './colors';

const bgLevelsLight = {
	...backgroundLevels.light
};

bgLevelsLight.level11 = rgbToHex(darken(bgLevelsLight.level10, .05));
bgLevelsLight.level12 = rgbToHex(darken(bgLevelsLight.level11, .05));

bgLevelsDark.level11 = rgbToHex(lighten(bgLevelsDark.level10, .05));
bgLevelsDark.level12 = rgbToHex(lighten(bgLevelsDark.level11, .05));

export const themes = {};

export const themeVariants = {
	hw: {
		primary: {
			retail: hwOrange,
			wholesale: hwGreen
		},
		secondary: {
			retail: hwGreen,
			wholesale: hwOrange
		}
	},
	hf: {
		primary: {
			retail: hfBrown,
			wholesale: hfBrown
		},
		secondary: {
			retail: hfGreen,
			wholesale: hfGreen
		}
	},
	hq: {
		primary: {
			retail: hqRed,
			wholesale: hqRed
		},
		secondary: {
			retail: grey,
			wholesale: grey
		}
	},
	tib: {
		primary: {
			retail: tibBlue,
			wholesale: tibBlue
		},
		secondary: {
			retail: grey,
			wholesale: grey
		}
	}
};

export default function createTheme(themeConfig = {}, config = {}) {
	const { mode = 'light' } = config;

	const {
		userType = 'retail',
		isRetail = true,
		isWholesale = false,
		themeVariantId = 'hw'
	} = config;

	const themeKey = `${themeVariantId}-${mode}-${userType}`;

	if (themes[themeKey]) {
		return themes[themeKey];
	}

	const primaryColor = themeVariants[themeVariantId]?.primary?.[userType] || themeVariants.default.primary[userType];
	const secondaryColor = themeVariants[themeVariantId]?.secondary?.[userType] || themeVariants.default.secondary[userType];

	const isFactoryColorScheme = themeVariantId === 'hf';

	const colorSchemes = {
		light: {
			palette: {
				primary: isFactoryColorScheme ? {
					main: primaryColor[500],
					light: primaryColor[100],
					dark: primaryColor[800]
				} : {
					main: primaryColor[800],
					light: primaryColor[100],
					dark: primaryColor[900]
				},
				secondary: isFactoryColorScheme ? {
					main: secondaryColor[700],
					light: secondaryColor[100],
					dark: secondaryColor[900]
				} : {
					main: secondaryColor[800],
					light: secondaryColor[100],
					dark: secondaryColor[900]
				},
				default: {
					main: grey[300],
					light: grey[200],
					dark: grey[500]
				},
				background: {
					default: grey[50],
					placeholder: grey[300],
					overlay: grey[200]
				},
				text: {
					primary: grey[700],
					secondary: grey[500],
					disabled: fade(grey[900], .5),
					icon: fade(grey[900], .5)
				},
				divider: 'rgba(0, 0, 0, .06)'
			}
		},
		dark: {
			palette: {
				primary: isFactoryColorScheme ? {
					main: primaryColor[200],
					light: primaryColor[100],
					dark: primaryColor[800]
				} : {
					light: primaryColor[100],
					main: primaryColor[300],
					dark: primaryColor[800]
				},
				secondary: isFactoryColorScheme ? {
					main: secondaryColor[500],
					light: secondaryColor[300],
					dark: secondaryColor[700]
				} : {
					light: secondaryColor[100],
					main: secondaryColor[300],
					dark: secondaryColor[800]
				},
				default: {
					main: rgbToHex(lighten(bgLevelsDark.level10, .24)),
					light: rgbToHex(lighten(bgLevelsDark.level10, .72)),
					dark: bgLevelsDark.level10
				},
				background: {
					default: bgLevelsDark.level2,
					paper: bgLevelsDark.level5,
					placeholder: bgLevelsDark.level12,
					overlay: bgLevelsDark.level7
				},
				text: {
					primary: blueGrey[50],
					secondary: blueGrey[300],
					disabled: fade(blueGrey[100], .5),
					icon: fade(blueGrey[100], .5)
				},
				divider: 'rgba(255, 255, 255, .06)'
			}
		}
	};

	const theme = extendTheme({
		...themeConfig,
		cssVariables: {
			cssVarPrefix: `${themeKey}`
		},
		typography: {
			fontFamily: '"Hind Siliguri", sans-serif',
			fontWeightLight: 400,
			fontWeightRegular: 500,
			fontWeightMedium: 600
		},
		shape: {
			borderRadius: 16
		},
		colorSchemes
	}, {
		...config,
		backgroundLevels: {
			dark: bgLevelsDark,
			light: bgLevelsLight
		}
	});

	const { spacing } = theme;
	const { palette } = theme.vars;
	const { darkMode, lightMode } = theme.config;
	const { alpha } = theme.helpers;

	theme.config = deepmerge(theme.config, {
		mode,
		key: themeKey,
		userType,
		isRetail,
		isWholesale,
		hwOrange: hwOrange[700],
		hwGreen: hwGreen[700],
		hfBrown: hfBrown[800],
		hfGreen: hfGreen[800],
		hqRed: hqRed[700],
		tibBlue: tibBlue[700],
		facebookColor,
		instagramColor,
		pinterestColor,
		whatsAppColor,
		youtubeColor,
		goldColor: '#dfc377',
		limeColor: hwLightGreen[300],
		retailColor: isRetail ? 'primary' : 'secondary',
		wholesaleColor: isWholesale ? 'primary' : 'secondary',
		goldGradient: 'linear-gradient(to right, #D7AD55 0%, #D9B25E 8%, #DFC377 22%, #E3CD87 29%, #E7DDA5 34%, #EBE8BA 38%, #F0EFAF 45%, #F5D08A 67%, #D7AD55 100%)',
		limeGradient: 'linear-gradient(to right, #a9d656 0%, #aed95d 8%, #dbfbac 22%, #d4f5a9 29%, #c5e8a5 34%, #c5e8a5 38%, #c6f0af 45%, #d3f589 67%, #a9d656 100%)',
		overlayColor: alpha('text.primary', darkMode ? .09 : .06),
		disabledColor: alpha('text.primary', .12),
		fontSize: {
			xs: '0.875rem',
			sm: '1rem'
		}
	});

	theme.mixins.goldButton = {
		color: '#000',
		backgroundColor: theme.config.goldColor,
		'&:hover, &.active': {
			backgroundColor: '#d7ad55'
		},
		'&.active:hover': {
			backgroundColor: '#dda735'
		}
	};

	theme.mixins.limeButton = {
		color: darken(hwLightGreen[900], .2),
		backgroundColor: hwLightGreen[300],
		'&:hover, &.active': {
			backgroundColor: hwLightGreen[400]
		},
		'&.active:hover': {
			backgroundColor: hwLightGreen[500]
		}
	};

	theme.mixins.goldText = {
		background: theme.config.goldGradient,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontSize: {
			xs: theme.typography.pxToRem(16),
			sm: theme.typography.pxToRem(20),
			md: theme.typography.pxToRem(24),
			xl: theme.typography.pxToRem(32)
		}
	};

	theme.mixins.limeText = {
		background: theme.config.limeGradient,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontSize: {
			xs: theme.typography.pxToRem(16),
			sm: theme.typography.pxToRem(20),
			md: theme.typography.pxToRem(24),
			xl: theme.typography.pxToRem(32)
		}
	};

	theme.mixins = deepmerge(theme.mixins, {
		typography: {
			heading: {
				color: palette.text.primary,
				fontWeight: theme.typography.fontWeightMedium,
				fontSize: {
					xs: theme.typography.pxToRem(24),
					sm: theme.typography.pxToRem(30),
					md: theme.typography.pxToRem(36),
					lg: theme.typography.pxToRem(42)
				}
			},
			subheading: {
				color: palette.text.secondary,
				fontWeight: theme.typography.fontWeightRegular,
				fontSize: {
					xs: theme.typography.pxToRem(14),
					sm: theme.typography.pxToRem(16),
					md: theme.typography.pxToRem(18),
					lg: theme.typography.pxToRem(24)
				}
			}
		},
		navItemLeaf: (colorName = 'default') => ({
			'& > .MuiButton-root': {
				...(colorName === 'gold' ? {
					color: darkMode ? '#ffd06f' : '#b37901',
					'&:hover': {
						backgroundColor: darkMode ? alpha(theme.config.goldColor, .1) : alpha(theme.config.goldColor, .2)
					},
					'&.active': {
						backgroundColor: darkMode ? alpha(theme.config.goldColor, .2) : alpha(theme.config.goldColor, .4)
					},
					'&.active:hover': {
						backgroundColor: darkMode ? alpha(theme.config.goldColor, .3) : alpha(theme.config.goldColor, .6)
					}
				} : {
					color: palette[colorName][darkMode ? 'main' : 'dark'],
					'&:hover': {
						backgroundColor: alpha(`${colorName}-main`, darkMode ? .1 : .2)
					},
					'&.active': {
						backgroundColor: alpha(`${colorName}-main`, darkMode ? .2 : .4)
					},
					'&.active:hover': {
						backgroundColor: alpha(`${colorName}-main`, darkMode ? .3 : .6)
					}
				})
			}
		})
	});

	theme.components.MuiButton.variants[0].style = {
		...theme.mixins.btnBaseVariant('text-primary', .36, .72)
	};

	theme.components = deepmerge(theme.components, {
		MuiCssBaseline: {
			styleOverrides: {
				':root': {
					'--app-bar-height': '57px',
					[`${theme.breakpoints.up('xs')}`]: {
						'--app-bar-height': '49px'
					},
					[theme.breakpoints.up('sm')]: {
						'--app-bar-height': '65px'
					}
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				colorDefault: {
					backgroundColor: palette.background.paper
				}
			},
			defaultProps: {
				enableColorOnDark: false
			}
		},
		MuiButton: {
			variants: [{
				props: { color: 'hwGreen', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: hwGreen[800],
					'&:hover': {
						backgroundColor: hwGreen[900]
					}
				}
			}, {
				props: { color: 'hwOrange', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: hwOrange[800],
					'&:hover': {
						backgroundColor: hwOrange[900]
					}
				}
			}, {
				props: { color: 'hqRed', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: hqRed[700],
					'&:hover': {
						backgroundColor: darken(hqRed[700], .25)
					}
				}
			}, {
				props: { color: 'tibBlue', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: tibBlue[700],
					'&:hover': {
						backgroundColor: darken(tibBlue[700], .25)
					}
				}
			}, {
				props: { color: 'hfBrown', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: hfBrown[800],
					'&:hover': {
						backgroundColor: darken(hfBrown[800], .25)
					}
				}
			}, {
				props: { color: 'hfGreen', variant: 'solid' },
				style: {
					color: '#fff',
					backgroundColor: hfGreen[900],
					'&:hover': {
						backgroundColor: darken(hfGreen[900], .25)
					}
				}
			}],
			styleOverrides: {
				containedPrimary: {
					'&&:not(.Mui-disabled)': {
						color: palette.primary[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('primary-main', .1),
						'&:hover': {
							backgroundColor: alpha('primary-main', .2)
						}
					}
				},
				containedSecondary: {
					'&&:not(.Mui-disabled)': {
						color: palette.secondary[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('secondary-main', .1),
						'&:hover': {
							backgroundColor: alpha('secondary-main', .2)
						}
					}
				},
				containedDefault: {
					'&&:not(.Mui-disabled)': {
						backgroundColor: alpha(`default-${darkMode ? 'light' : 'dark'}`, darkMode ? .15 : .2),
						'&:hover': {
							backgroundColor: alpha(`default-${darkMode ? 'light' : 'dark'}`, darkMode ? .25 : .3)
						}
					}
				},
				containedError: {
					'&&:not(.Mui-disabled)': {
						color: palette.error[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('error-main', .1),
						'&:hover': {
							backgroundColor: alpha('error-main', .2)
						}
					}
				},
				containedSuccess: {
					'&&:not(.Mui-disabled)': {
						color: palette.success[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('success-main', .1),
						'&:hover': {
							backgroundColor: alpha('success-main', .2)
						}
					}
				},
				containedWarning: {
					'&&:not(.Mui-disabled)': {
						color: palette.warning[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('warning-main', .1),
						'&:hover': {
							backgroundColor: alpha('warning-main', .2)
						}
					}
				},
				containedInfo: {
					'&&:not(.Mui-disabled)': {
						color: palette.info[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha('info-main', .1),
						'&:hover': {
							backgroundColor: alpha('info-main', .2)
						}
					}
				},
				outlinedPrimary: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('primary-main', .5),
						'&:hover': {
							borderColor: palette.primary.main
						}
					}
				},
				outlinedSecondary: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('secondary-main', .5),
						'&:hover': {
							borderColor: palette.secondary.main
						}
					}
				},
				outlinedError: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('error-main', .5),
						'&:hover': {
							borderColor: palette.error.main
						}
					}
				},
				outlinedSuccesss: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('success-main', .5),
						'&:hover': {
							borderColor: palette.success.main
						}
					}
				},
				outlinedWarning: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('warning-main', .5),
						'&:hover': {
							borderColor: palette.warning.main
						}
					}
				},
				outlinedInfo: {
					'&&:not(.Mui-disabled)': {
						borderColor: alpha('info-main', .5),
						'&:hover': {
							borderColor: palette.info.main
						}
					}
				},
				solidDefault: {
					'&&:not(.Mui-disabled)': {
						color: darkMode ? grey[900] : grey[900],
						backgroundColor: darkMode ? grey[100] : grey[100],
						'&:hover': {
							backgroundColor: darkMode ? grey[300] : grey[300]
						}
					}
				}
			},
			defaultProps: {
				disableElevation: true
			}
		},
		MuiLink: {
			variants: [{
				props: { color: 'default.main' },
				style: {
					'&&': {
						color: palette.default[darkMode ? 'light' : 900],
						textDecorationColor: alpha(`default-${darkMode ? 'light' : 'dark'}`, .4),
						'&:hover, &:focus, &:active': {
							textDecorationColor: 'inherit'
						}
					}
				}
			}]
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					boxShadow: 'none',
					border: `solid 1px ${palette.divider}`,
					...(darkMode && {
						backgroundColor: palette.background.level10
					})
				}
			}
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					boxShadow: 'none',
					border: 'none'
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					border: 'none'
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {
					'&, &:hover, &:focus, &:active': {
						boxShadow: 'none'
					}
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				size: 'small'
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&&': {
						borderRadius: theme.spacing(3),
						backgroundColor: theme.config.overlayColor,
						'&.Mui-disabled': {
							backgroundColor: theme.config.disabledColor
						}
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					transition: 'none'
				}
			},
			variants: [{
				props: { indicatorColor: 'inherit' },
				style: {
					'&& .MuiTabs-indicator': {
						backgroundColor: 'currentColor'
					}
				}
			}]
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					'&.MuiDrawer-modal': {
						'& > .MuiDrawer-paperAnchorLeft': {
							borderTopRightRadius: theme.shape.borderRadius,
							borderBottomRightRadius: theme.shape.borderRadius
						},
						'& > .MuiDrawer-paperAnchorRight': {
							borderTopLeftRadius: theme.shape.borderRadius,
							borderBottomLeftRadius: theme.shape.borderRadius
						}
					}
				}
			},
			defaultProps: {
				elevation: 0
			}
		},
		RaButton: {
			defaultProps: {
				shape: 'rounded'
			}
		},
		RaLoadingSnackbar: {
			styleOverrides: {
				content: {
					borderRadius: 24
				}
			}
		},
		RaDrawerMenuNavItem: {
			styleOverrides: {
				itemLeaf: {
					'& > .MuiButton-root': {
						textTransform: 'uppercase',
					},
					marginBottom: spacing(.25),
					paddingRight: spacing(1),
					'& ul > li': {
						paddingRight: 0,
						paddingLeft: theme.spacing(1.5),
						fontSize: theme.typography.pxToRem(14),
						'& > .MuiButton-root': {
							textTransform: 'none',
							paddingLeft: theme.spacing(2),
							borderRadius: theme.spacing(8),
							border: 'none'
						}
					},
					'&.RaDrawerMenuNavItem-anniversaryLabel': {
						'& > .MuiListSubheader-root': {
							color: alpha(palette.text.primary, .75),
							fontSize: theme.typography.pxToRem(14)
						}
					},
					'&.RaDrawerMenuNavItem-anniversary': {
						marginBottom: spacing(1),
						'& > .MuiButton-root': {
							...theme.mixins.goldButton
						}
					},
					'&.RaDrawerMenuNavItem-hq': {
						marginBottom: spacing(1.5),
						...theme.mixins.navItemLeaf('error'),
						'&& > .MuiButton-root': {
							textTransform: 'none'
						}
					}
				},
				button: {
					letterSpacing: .25,
					borderRadius: spacing(0, 8, 8, 0),
					color: palette.text.primary,
					paddingTop: spacing(.75),
					paddingBottom: spacing(.75)
				},
				active: {
					color: palette.primary[darkMode ? 'main' : 'dark'],
					backgroundColor: alpha(`primary-${darkMode ? 'light' : 'main'}`, .1),
					'&:hover': {
						backgroundColor: alpha(`primary-${darkMode ? 'light' : 'main'}`, .15)
					}
				},
				icon: {
					marginRight: spacing(-.75)
				},
				subheader: {
					color: alpha('text-primary', .5),
					'&.active': {
						color: palette.primary[darkMode ? 'main' : 'dark']
					}
				}
			}
		},
		RaDialog: {
			styleOverrides: {
				title: {
					flexDirection: 'row',
					alignItems: 'baseline',
					flexWrap: 'wrap',
					columnGap: theme.spacing(1),
					[theme.breakpoints.up('md')]: {
						columnGap: theme.spacing(1.5)
					}
				},
				heading: {
					marginRight: spacing(.75),
					[theme.breakpoints.up('md')]: {
						marginRight: 0
					}
				},
				subheading: {
					color: palette.text.secondary,
					fontWeight: theme.typography.fontWeightRegular
				}
			},
			defaultProps: {
				fullScreen: false
			}
		},
		RaFinalFormFilePond: {
			styleOverrides: {
				filepond: {
					border: `solid 1px ${alpha('text-primary', .23)})`,
					'&:hover': {
						borderColor: palette.text.primary
					},
					'@media (hover: none)': {
						borderColor: alpha('text-primary', .23)
					}
				}
			}
		},
		RaFullscreenDialog: {
			styleOverrides: {
				container: {
					backgroundColor: palette.background.default
				}
			}
		},
		RaAlertBox: {
			styleOverrides: {
				root: {
					boxShadow: 'none'
				}
			}
		},
		RaAvatar: {
			styleOverrides: {
				default: {
					backgroundColor: palette.default[darkMode ? 'dark' : 'light']
				}
			}
		},
		RaHtmlParser: {
			styleOverrides: {
				root: {
					[theme.breakpoints.up('xs')]: {
						fontSize: theme.typography.pxToRem(14),
						'& > h2': {
							fontSize: theme.typography.pxToRem(16)
						},
						'& > h3': {
							fontSize: theme.typography.pxToRem(14)
						}
					},
					[theme.breakpoints.up('sm')]: {
						fontSize: theme.typography.pxToRem(16),
						'& > h2': {
							fontSize: theme.typography.pxToRem(20)
						},
						'& > h3': {
							fontSize: theme.typography.pxToRem(18)
						}
					}
				}
			}
		},
		RaAppBarNav: {
			styleOverrides: {
				button: {
					letterSpacing: .25,
					fontSize: theme.typography.pxToRem(13),
					padding: theme.spacing(.25, 1),
					[theme.breakpoints.up('lg')]: {
						fontSize: theme.typography.pxToRem(14),
						padding: theme.spacing(.5, 1.25),
					}
				},
				itemLeaf: {
					padding: theme.spacing(0, .125)
				},
				itemActive: {
					'& > .MuiButton-root': {
						color: palette.primary[darkMode ? 'main' : 'dark'],
						backgroundColor: alpha(`primary-${darkMode ? 'light' : 'main'}`, .1),
						'&:hover': {
							backgroundColor: alpha(`primary-${darkMode ? 'light' : 'main'}`, .15)
						}
					}
				},
				active: {
					borderLeft: 'none'
				},
				menu: {
					'& > .MuiMenu-paper > .MuiList-root': {
						padding: theme.spacing(.5, .75),
						'& > .MuiListItem-root': {
							padding: 0,
							margin: theme.spacing(.25, 0)
						}
					}
				}
			}
		}
	});

	themes[themeKey] = theme;

	return theme;
}
